import Link from "next/link";
import Image from "next/image";
import LogoLimbar from "@/public/logo-gradient.svg";

export default function Logo() {
  return (
    <Link href="/" className="inline-flex" aria-label="Limbar">
      <div className="flex items-center">
        <Image
          className="relative"
          src={LogoLimbar}
          width={32}
          height={32}
          alt="Logo"
          priority
        />
        <span className="text-xl font-bold text-gray-900 ml-1">limbar</span>
      </div>
    </Link>
  );
}
